.buttonContainer {
    display: flex;
    flex-direction: row; /* Align buttons in a row */
    align-items: center;
    gap: 10px; /* Space between buttons */
    justify-content: center; /* Center buttons horizontally */
    margin-top: 40px;
    margin-bottom: 10px;
}

.buttonContainer button {
    flex-grow: 1;
    margin: 0 20px;
}

.actionButton {
    background-color: #256EFF; /* Example color */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    width: 100%; /* Ensure both buttons are the same width */
    transition: background-color 0.3s;
    font-family: Inter, sans-serif;
}

.actionButton:hover {
    background-color: #1a5db8; /* Darker shade for hover effect */
}

.redButton {
    background-color: var(--color-btn-danger); /* Red color for the accept button */
}

.redButton:hover {
    background-color: var(--color-btn-danger-hover); /* Darker red on hover */
}

.blueButton {
    background-color: var(--color-btn-primary); /* Blue color for the cancel button */
}

.blueButton:hover {
    background-color: var(--color-btn-primary-hover); /* Darker blue on hover */
}

