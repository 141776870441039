.cardItem {
    display: flex;
    width: 310px;
    background-color: #fff;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 18px rgba(140, 153, 198, 0.25));
    background-image: url("./background-bottom-right.svg"), url("./background-top-left.svg");
    background-repeat: no-repeat;
    background-position: bottom right, top left;
}

.cardItemContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 0;
    padding: 2em;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.pricingItemContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.pricingTitle {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 2rem;
}

.pricingTitle p {
    margin: 0;
}

.pricingMainContent {
    text-align: left;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    overflow-y: scroll;
}

.currencySymbol {
    vertical-align: super;
    font-size: 0.6em; /* Adjust the size as needed */
    margin-right: 0.2em; /* Adjust the spacing as needed */
}

.productItemPrice {
    padding-right: 5px;
    font-family: "Inter", sans-serif;
    font-size: 35px;
    font-weight: 600;
}

.pricingItemPriceDesc {
    color: rgba(1, 5, 27, 0.56);
    border-left: 2px solid rgba(1, 5, 27, 0.06);
    margin-left: 5px;
    padding-left: 13px;
    font-size: 10px;
}

.pricingItemDescription {
    color: #606060;
    padding-top: 3em;
    padding-bottom: 1em;
    font-size: 12px;
    font-weight: 500;
}

.pricingItemButtonHolder {
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: stretch;
    display: flex;
}

.checkoutButton {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    border: none;
    text-align: center;
    letter-spacing: 0;
    background: var(--color-btn-primary);
    border-radius: 5px;
    margin-bottom: 0;
    margin-right: 0;
    padding: 13px 20px;
    font-family: "Inter", sans-serif;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.checkoutButton:hover {
    background-color: var(--color-btn-primary-hover);
}
