.studyCardOnlyTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    box-sizing: border-box;
    max-width: 800px;
    min-height: 372px;
    box-shadow: 1px 2px 45px 4px #7090b033;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin: 25px auto;
}

.content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 372px;
}

.cardColoring {
    width: 1px;
    height: inherit;
    background: linear-gradient(
        rgba(255, 255, 255, 0.48) 10%,
        rgba(128, 169, 249, 0.51) 20%,
        rgba(2, 84, 242, 0.6) 50%,
        rgba(128, 169, 249, 0.54) 80%,
        rgba(255, 255, 255, 0.48) 90%
    );
}

.card {
    box-sizing: border-box;
    height: 100%;
    width: inherit;
}

.cardHeadline {
    box-sizing: border-box;
    width: 100%;
    min-height: 62px;
    max-height: 120px;
    border-bottom: 2px solid rgba(241, 243, 248, 0.9);
    display: flex;
    flex-direction: row;
    overflow-y: auto;
}

.headlineInput {
    box-sizing: border-box;
    background: transparent;
    padding: 20px 16px 10px 39px;
    border: none;
    outline: none;
    color: #494949;
    overflow: auto;
    resize: none;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 90%;
    scrollbar-width: thin;
}

.number {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: "Inter", sans-serif;
    color: #494949;
    font-size: 14px;
    margin-top: 15px;
    margin-right: 15px;
}

.headlineInput::-webkit-scrollbar {
    display: none;
}

.cardContent {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    overflow: hidden;
}

.multipleChoiceAnswers {
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
}

.answer {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

.answerInput {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    resize: none;
    border: none;
    margin-left: 10px;
    width: 100%;
    min-height: 25px;
    padding-top: 10px;
    vertical-align: middle;
}

.answerInput:focus-visible {
    outline: none;
}

input[type="checkbox"].answerCheckbox {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 17px 0 0 10px;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 1.5px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"].answerCheckbox::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em currentColor;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"].answerCheckbox:checked::before {
    transform: scale(1);
}

.controlIcons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 15px);
    margin-right: 15px;
    margin-left: 15px;
    align-items: flex-end;
}

.icon {
    height: 24px;
    cursor: pointer;
    margin-top: 10px;
}

.cliploader {
    max-width: 20px;
    max-height: 20px;
    opacity: 0.7;
    color: #494949;
}

.svg-icon {
    width: 24px; /* Equivalent to w-6 */
    height: 24px; /* Equivalent to h-6 */
    object-fit: contain;
}

.scrollable {
    overflow-y: auto;
}

.loader {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 372px;
    box-shadow: 1px 2px 45px 4px #7090b033;
    border-radius: 10px;
    margin-left: 22px;
    margin-right: 4%;
}
