.title {
    max-width: 100%;
    height: 22px;
    margin-top: 20px;
    padding: 18px 18px 10px 2px;
    border-bottom: 1px solid #e2e8f0;
    font-family: "Inter", sans-serif;
    color: #494949;
}

.input {
    outline: none;
    text-align: left;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    color: #494949;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}