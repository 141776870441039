.editable-container::-webkit-scrollbar {
    display: none;
}

.wrapper {
    display: flex;
    height: calc(100svh - var(--bottombar-height) - var(--toolbar-height) - 13px);
    width: calc(100% - 8%);
    padding: 6px 4%;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media screen and (max-width: 768px) {
    .editable-container {
        width: max-content;
    }
    .wrapper {
        padding: 6px 10px;
        height: calc(100svh - var(--bottombar-height) - var(--toolbar-height) - 70px);
        width: calc(100svh - 8%);
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
}

.title {
    max-width: 100%;
    height: 22px;
    margin-top: 20px;
    padding: 18px 18px 10px 2px;
    border-bottom: 1px solid #e2e8f0;
    font-family: "Inter", sans-serif;
    color: #494949;
}

.input {
    outline: none;
    text-align: left;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    width: 100%;
    color: #494949;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upgradeCard {
    width: 100%;
    margin-top: 30px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.demo-dfree {
    margin-top: 26px;
    position: relative;
    text-align: left;
    color: #373d49;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    text-wrap: wrap;
}

.demo-dfree *[contentEditable="true"]:focus {
    outline: none;
}

.demo-dfree h1 {
    line-height: 2rem;
}

.demo-dfree a {
    text-decoration: underline;
}
.demo-dfree textarea {
    display: none;
}
.demo-dfree .dfree-header {
    font-size: 2.4em;
    text-align: center;
    margin: 10px;
}
.demo-dfree .dfree-body {
    padding: 0 20px 20px;
}
.demo-dfree .dfree-body p {
    margin-bottom: 10px;
}
.demo-dfree .dfree-body blockquote {
    margin-left: 30px;
    padding-left: 10px;
    margin-right: 40px;
    font-style: italic;
    border-left: 1px solid #696969;
}
.demo-dfree .dfree-body a {
    text-decoration: underline;
}
.demo-dfree .dfree-body td,
.demo-dfree .dfree-body tr {
    border: 1px solid #696969;
}

.demo-dfree abbr {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    background-color: #3498db;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 12px; /* Adjusted font size to fit the smaller circle */
    font-weight: bold;
    position: relative;
    top: -2px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}

/* Hover effect */
.demo-dfree abbr:hover {
    background-color: #2980b9;
    transform: scale(1.1);
}

/* Optional: add a shadow effect */
.demo-dfree abbr {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Optional: focus effect for accessibility */
.demo-dfree abbr:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5);
}

@media screen and (max-width: 768px) {
    .demo-dfree h1,
    .demo-dfree h2,
    .demo-dfree h3,
    .demo-dfree h4,
    .demo-dfree h5,
    .demo-dfree h6,
    .demo-dfree p,
    .demo-dfree blockquote,
    .demo-dfree a,
    .demo-dfree ul,
    .demo-dfree ol,
    .demo-dfree li,
    .demo-dfree div,
    .demo-dfree span,
    .demo-dfree pre,
    .demo-dfree code,
    .demo-dfree table,
    .demo-dfree thead,
    .demo-dfree tbody,
    .demo-dfree tfoot,
    .demo-dfree td,
    .demo-dfree tr,
    .demo-dfree th,
    .demo-dfree img {
        max-width: 80svw;
    }
}
