.container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100svh - 20px - 45px - 13px);
    gap: 16px;
    overflow-y: auto;
    padding-left: 4%;
    padding-right: 4%;
}

.containerStudyMode {
    display: flex;
    position: relative;
    flex-direction: column;
    height: calc(100svh - 72px - 20px - 24px - 20px);
    align-items: center;
    border-radius: 14px;
    justify-content: center;
    /*box-shadow: 0px 17px 40px 4px #7090B01C;*/
    margin-left: 2%;
    margin-right: 2%;
    /*overflow-y: auto;*/
}

.deckTitle {
    position: absolute;
    top: 50px;
    margin-bottom: 100px;
    font-family: var(--primary-font), sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@media screen and (max-width: 768px) {
    .container {
        height: calc(100svh - 20px - 45px - 13px - 50px);
    }

    .containerStudyMode {
        overflow-y: auto;
    }

    .deckTitle {
        display: none;
    }
}

.data {
    position: absolute;
    height: 30px;
    text-align: left;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 10px;

    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 700;

    color: #494949;
}

.dataTitle {
    margin-right: 10px;
}

.analyticsTitle {
    text-decoration: underline;
    cursor: pointer;
}

.analytics {
    position: absolute;
    top: 80px;
    left: 0;
    width: 250px;
    height: 376px;
    margin-left: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
}

.smallAnalyticsTitle {
    height: 20px;
    text-align: left;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;

    color: #494949;
}

.smallAnalyticsText {
    height: 14px;
    text-align: left;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 5px;

    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 300;

    color: #494949;
}

.smallAnalyticsLog::before {
    content: "\2022"; /* Unicode for a bullet point */
    padding-right: 5px;
}

.smallAnalyticsLog {
    height: 24px;
    text-align: left;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 5px;

    font-family: "Exo 2", sans-serif;
    font-size: 12px;
    font-weight: 300;

    color: #494949;
}

.separator {
    height: 20px;
}

.stats {
    position: absolute;
    bottom: -60px;
    height: 20px;
    padding-left: 10px;
    padding-top: 10px;
    font-family: var(--primary-font), sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #494949;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.menuContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.optionText {
    vertical-align: middle;
}

.optionIcon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 8px;
}

.title {
    max-width: 100%;
    height: 22px;
    margin-left: 22px;
    margin-right: 4%;
    padding: 18px 18px 20px 32px;
    border-bottom: 1px solid #e2e8f0;
}

.inputDeckTitle {
    border: none;
    width: 100%;
}

.headlineInput {
    outline: none;
    text-align: left;
    color: #494949;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}

.buttonContainer {
    display: flex;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    border: 1px solid #e0e5f2;
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.uploadContainer {
    position: relative; /* Added this */
    margin-top: 14px;
    max-width: 100%;
    min-height: 372px;
    margin-left: 22px;
    margin-right: 4%;
    background: linear-gradient(15.46deg, #2256ef 26.3%, #7b5aff 86.4%);
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconCircle {
    position: absolute; /* Added this */
    top: -40px; /* Half the height of the circle */
    left: 50%; /* Move it to the center horizontally */
    transform: translateX(-50%); /* Center it based on its width */
    width: 80.35px;
    height: 80.35px;
    border-radius: 50%;
    border: 4.27px solid #ffffff;
    background: linear-gradient(15.46deg, #2256ef 26.3%, #7b5aff 86.4%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadIcon {
    width: 24px;
    height: 24px;
}

.uploadTextTitle,
.uploadTextSubtitle {
    font-family: inter, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    color: #ffffff;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 12px;
}

.uploadArea {
    margin-top: 30px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    padding: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadBtn {
    margin-top: 35px;
    min-height: 45px;
    min-width: 150px;
    padding: 10px 20px;
    background: #ffffff24;
    font-family: "Exo 2", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 45px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.uploadBtn:hover {
    background-color: #4b24b1;
}

.buttons {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.addCardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
    position: relative;
}

.button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-btn-secondary-hover);
    cursor: pointer;
    font-size: 22px;
    text-align: center;
}

.createCardButton {
    width: 220px;
}
