.errorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.errorMessage {
  padding: 20px 30px;
  background-color: #d32f2f;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  text-align: center;
  width: auto;
  max-width: 400px;
}

.button {
  margin-top: 20px;
  margin-right: 10px; /* Adds spacing between buttons */
  padding: 8px 16px;
  background-color: #ba000d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: #9a0007;
}

.button.deactivated {
  background-color: #9a0007;
  cursor: not-allowed;
}

.button.deactivated:hover {
  background-color: #9a0007;
}

.button.submitting {
  background-color: #9a0007;
  color: white;
  cursor: progress;
}

.button.success {
  background-color: #9a0007;
  cursor: not-allowed;
}
