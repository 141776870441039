.inlineCard {
    position: relative; /* Added this */
    width: 90%;
    margin: 20px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: 0 4px 10px 0 rgba(140, 153, 198, 0.25);
    background: #fff;
    padding: 10px 10px 10px 10px;

    color: var(--color-btn-primary);
}

.inlineCard::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #fff;
    background-image: conic-gradient(from 137deg at 45% 47%, #FFF 3%, #000AFFFF 23%, #FFF 42%, #FFFFFFFF 53%, #000AFFFF 78%, #fff 92%),radial-gradient(113% 91% at 17% -2%, #FF5A00FF 1%, #FF000000 99%),radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
    padding: 0.09em;
    border-radius: 5px;
}

.inlineCard p {
    font-family: "Inter", sans-serif;
    font-size: 0.8em;
    font-weight: 500;
}

.inlineCard button {
    margin-top: 10px;
    margin-bottom: 10px;
}