.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
}

.pdficon {
    width: 48px;
    height: 48px;
}

.window {
    width: 70%;
    height: 85%;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 14px 27px 45px 4px #7090b033;
    padding-left: 30px;
    padding-right: 30px;
}

.preparingfilemanager {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 8px;
    color: #494949;
}

.toolbar {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 20px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    box-shadow: rgba(140, 153, 198, 0.25) 0px 4px 6px 0px;
}

.filemanager {
    width: 100%;
    height: calc(100% - 60px - 5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: hidden;
}

.filemanager::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.wrapper {
    flex-direction: column;
    min-height: calc(100% - 60px - 5px);
}

.wrapper > *:not(:last-child) {
    margin-bottom: 5px; /* Apply to all child elements except the last one */
}

.title {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    justify-content: center;
    text-align: left;
}

.controlbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 20px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 4px;
    font-size: 30px;
    cursor: pointer;
}

.button:hover {
    background: #f8f9fa;
}

.workspaceopen {
    width: calc(100% - 2px);
    min-height: 118px;
    background: #ffffff;
}

.workspaceclosed {
    width: calc(100% - 2px);
    height: 58px;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.workspaceheader {
    width: calc(100% - 2px);
    height: 58px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.workspaceopen .workspaceheader {
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 10px;
}

.workspacetitle {
    height: 58px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.workspaceopen .workspacetitle {
    font-weight: 700;
}

.workspacecontrolbar {
    height: 30px;
    min-width: 30px;
    flex-direction: row-reverse;
}

.inputformworkspace {
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    outline: none;
    border: none;
    box-sizing: border-box;
    text-align: left;
    margin-left: 4px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.ellipsis {
    transform: translateY(2px);
    height: 30px;
    width: 30px;
    margin-right: 5px;
    align-items: center;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    margin-left: 15px;
}

.controlbaricon {
    margin-left: 5px;
    height: 30px;
    width: 0px;
    margin-right: 5px;
    align-items: center;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
}

.arrowopen {
    transform: translateY(4px);
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: none;
    background: #ffffff;
}

.arrowclosed {
    transform: translateY(4px);
    cursor: pointer;
    border: none;
    background: #ffffff;
    width: 48px;
    height: 30px;
}

.subworkspaceheader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.subworkspacetitle {
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.subworkspacecontrolbar {
    height: 30px;
    min-width: 30px;
    display: flex;
    flex-direction: row;
    margin-right: 2px;
}

.inputformsubworkspace {
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    border: none;
    box-sizing: border-box;
    text-align: left;
    margin-left: 4px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.subspacetitle {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    justify-content: center;
    text-align: left;
}

.subworkspaceopen {
    width: 100%;
    min-height: 120px;
    border-radius: 10px;
}

.subworkspaceclosed {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.subspacearrowopen {
    background: none;
    cursor: pointer;
    width: 48px;
    height: 30px;
    border: none;
}

.subspacearrowclosed {
    background: none;
    transform: translateY(2px);
    cursor: pointer;
    border: none;
    width: 48px;
    height: 30px;
}

.filestoreworkspace {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.filestoreworkspacefiles {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    gap: 5px 20px;
    flex-wrap: wrap;
}

.filestoreworkspacefiles > div {
    /* Assuming each file is wrapped in a div */
    flex: 0 1 auto; /* Adjusts flex-shrink and flex-basis */
    min-width: 100px; /* Example min-width, adjust based on your needs */
}

.filestoresubspace {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.filestoresubspacefiles {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    gap: 5px 20px;
    flex-wrap: wrap;
}

.filestoresubspacefiles > div {
    /* Assuming each file is wrapped in a div */
    flex: 0 1 auto; /* Adjusts flex-shrink and flex-basis */
    min-width: 100px; /* Example min-width, adjust based on your needs */
}

.folderheader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.foldertitlewrapper {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.inputformfolder {
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    border: none;
    box-sizing: border-box;
    text-align: left;
    margin-left: 4px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.foldertitle {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #1b2559;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    justify-content: center;
    text-align: left;
}

.foldercontrolbar {
    height: 30px;
    display: flex;
    flex-direction: row;
    margin-right: 2px;
}

.folderarrowopen {
    transform: translateY(2px);
    cursor: pointer;
    width: 48px;
    height: 30px;
    border: none;
    background: #ffffff;
}

.folderarrowclosed {
    transform: translateY(2px);
    cursor: pointer;
    border: none;
    background: #ffffff;
    width: 48px;
    height: 30px;
}

.folderclosed {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.folderopen {
    width: 100%;
    min-height: 120px;
    border-radius: 10px;
    background: #ffffff;
}

.filestorefolder {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px 20px;
}

.filestorefolder > div {
    /* Assuming each file is wrapped in a div */
    flex: 0 1 auto; /* Adjusts flex-shrink and flex-basis */
    min-width: 100px; /* Example min-width, adjust based on your needs */
}

.filewrapper {
    width: 100px;
    height: auto;
    position: relative;
    padding: 5px;
    transition: all 0s ease;
    overflow: hidden;
}

.filewrapper:hover {
    overflow: visible;
}

.filewrapper:hover .fileicon {
    transform: scale(1.1); /* Scale up on hover */
}

.filewrapper:hover .filetitle {
    font-weight: 600;
}

.filewrapper:hover .filerenameicon,
.filewrapper:hover .filedeleteicon {
    display: block; /* Show icons on hover */
}

.fileicon {
    transition: all 0.3s ease; /* Smooth transition for child elements */
    width: 34px;
    height: 44px;
    border-radius: 4px;
    cursor: pointer;
    margin: 2px auto;
}

.fileEditIcons {
    margin-top: 2px;
    height: 17px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.filerenameicon {
    display: none; /* Hide icons by default */
    cursor: pointer;
}

.filedeleteicon {
    display: none; /* Hide icons by default */
    cursor: pointer;
}

.filewrapper:hover .filerenameicon {
    display: inline; /* Show icons on hover */
}

.filewrapper:hover .filedeleteicon {
    display: inline; /* Show icons on hover */
}

.filetitlewrapper {
    margin-top: 10px;
    width: 100%;
}

.filewrapper:hover .filetitle {
    overflow: hidden;
}

.inputformfile {
    color: #718096;
    background-color: #ffffff;
    font-family: "Inter", sans-serif;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    outline: none;
    border: 1px solid #d2d6dc; /* Adjusted for visibility */
    box-sizing: border-box;
    text-align: left;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    overflow: hidden; /* Adjust as needed */
    resize: none; /* Disable resize handle */
    padding: 4px; /* Add some padding */
    width: 100%; /* Adjust width to fit container */
    max-lines: 4;
}

.filetitle {
    display: block;
    width: 100%;
    height: 100%;
    color: #718096;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    line-break: anywhere;
}

.fileviewonlywrapper {
    width: 90px;
    height: auto;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.fileviewonlywrapper:hover {
    width: 90px;
}

.fileicon,
.filetitleviewonlywrapper {
    transition: all 0.3s ease; /* Smooth transition for child elements */
}

.fileviewonlywrapper:hover .filerenameicon,
.fileviewonlywrapper:hover .filedeleteicon {
    display: block; /* Show icons on hover */
}

.filetitleviewonly {
    display: inline-block;
    margin-top: 5px;
    width: 90px; /* Width limit after which text will wrap */
    color: #718096;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* Maintain readability for wrapped text */
    text-align: center;
    line-break: anywhere;
    text-overflow: ellipsis;
}

.findButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 24px;
    background: #ffffff;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .findButton {
        display: none;
    }
}

.findButtonElements {
    height: calc(100% - 12px);
    width: calc(100% - 24px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.findButtonIcon {
    width: 24px;
    height: 24px;
}

.findButtonForm {
    display: flex;
    width: 158px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    align-items: center;
    justify-content: space-around;
}

.findButtonFormInput {
    outline: none;
    border: none;
    background: #ffffff;
    font-family: "Inter", sans-serif;
    width: 140px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: rgb(113, 128, 150);
}

.findButtonFormInput::placeholder {
    color: #7180964d;
    font-weight: 600;
    opacity: 1;
}

.findButtonFormInput::-ms-input-placeholder {
    color: #7180964d;
}

.fileSearchWindow {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Example grid */
    gap: 15px; /* Adjust the gap between items */
    overflow-y: scroll;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale(0.95);
    }
}

.vanishing {
    animation-name: fadeOut;
    animation-duration: 0.5s; /* Or however long you want the animation to last */
    animation-fill-mode: forwards; /* Keeps the element in the state of the last animation frame */
}
