.uploadContainer {
    width: 508px;
    max-width: 85%;
    min-height: 331px;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px 0 rgba(140, 153, 198, 0.25);
    background-image: url("./background-line-bottom.svg"), url("./background-line-top.svg");
    background-position: bottom right, top left;
    background-repeat: no-repeat;
}

/* Style adjustments for when dragging over the container */
.uploadContainer.dragOver {
    border: 2px dashed #ffffff; /* Dashed border to indicate a drop zone */
    color: #000; /* Ensure text color is white for visibility */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Hide elements when dragging over */
.uploadContainer.dragOver .iconCircle,
.uploadContainer.dragOver .uploadTextTitle,
.uploadContainer.dragOver .uploadBtn {
    display: none;
}

/* Additional style for indicating "Drag to upload here..." message */
.dragOverMessage {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadIcon svg {
    width: 65px;
    height: 65px;
    margin-top: 20px;
}

.uploadTextTitle,
.uploadTextSubtitle {
    text-align: center;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 188.889% */

    letter-spacing: 0;
    max-width: 90%;
    color: #000;
    margin-top: 17px;
}

.uploadArea {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #000;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .uploadArea {
        flex-direction: column;
    }
}

.uploadBtn {
    margin: 20px 20px;
    min-height: 30px;
    padding: 5px 20px;
    background: var(--color-btn-primary);
    color: #fff;

    vertical-align: center;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.uploadBtn:hover {
    background: var(--color-btn-primary-hover);
}
