.button {
    align-items: center;
    display: flex;
    letter-spacing: 0px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    justify-content: space-around;
}

.plan {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    color: #256eff;
}

.upgradeText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: none;
    color: #256eff;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.window {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 80%;
    margin: auto;
    overflow-y: auto;
}

.header {
    background-color: #f7f7f7;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ececec;
}

.header span {
    font-size: 1.2em;
    font-weight: bold;
}

.header button {
    border: none;
    background: none;
    font-size: 1.5em;
    cursor: pointer;
}

.headline {
    margin-top: -10px;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .headline {
        margin-top: 20px;
    }
}

.gradientText {
    background: linear-gradient(95deg, #256eff 30.19%, #80a9f9 97.32%, #fff 124.04%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 2.4px;
    display: block; /* Makes the span behave like a div, taking up the full width like a row */
    -webkit-background-clip: text;
    background-clip: text; /* Standard syntax */
    -webkit-text-fill-color: transparent;
}

.content {
    padding: 20px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.plans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    margin-bottom: 40px;
}

.boxIcon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
}

.checkBoxes {
    margin: 20px auto;
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.checkboxItem {
    display: flex;
    align-items: center;
    text-align: left;
    flex-wrap: 1;
    width: 33%;
    margin-bottom: 0px; /* Adjust this as needed for spacing between checkboxes */
    flex-grow: 1;
}

.checkboxText {
    font-size: 12px;
    margin-left: 8px; /* Small margin to the left of the text */
}

.checkboxIcon {
    margin-top: 3px;
}

@media screen and (max-width: 768px) {
    .checkBoxes {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        left: 30%;
        transform: translateX(-50%);
        margin: 20px auto 30px auto;
    }

    .checkboxItem {
        gap: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .checkboxText {
        margin: 0;
    }
}
