.headline {
    margin-top: -10px;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-weight: 600;
}
.gradientText {
    background: linear-gradient(95deg, #256eff 30.19%, #80a9f9 97.32%, #fff 124.04%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 2.4px;
    display: block; /* Makes the span behave like a div, taking up the full width like a row */
    -webkit-background-clip: text;
    background-clip: text; /* Standard syntax */
    -webkit-text-fill-color: transparent;
}

.description {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    letter-spacing: 0.72px;
}

.buttonArea {
    margin-bottom: 40px;
}
