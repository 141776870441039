.menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    padding-bottom: 15px;
    width: 100%;
    box-shadow: 0 -4px 6px 0 rgba(140, 153, 198, 0.25);
    background: white;
}

.menuItems {
    flex-direction: row;
    gap: 1rem;
    display: none;
    list-style-type: none;
    justify-content: space-between;
    flex-shrink: 1;
    padding: 0;
    margin-bottom: 10px;
    background-color: white;
    width: 100%;
}

.menuBorder {
    height: 1px;
    width: 100%;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.48) 10%,
        rgba(128, 169, 249, 0.51) 20%,
        rgba(2, 84, 242, 0.6) 50%,
        rgba(128, 169, 249, 0.54) 80%,
        rgba(255, 255, 255, 0.48) 90%
    );
}

.menuItems li {
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menuItems li.active,
.menuItems li.active svg {
    color: var(--color-btn-primary);
}

.image {
    display: block;
}

.description {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .menu {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .menuItems {
        display: flex;
        z-index: 500;
    }
}
