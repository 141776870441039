.wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 2%;
    height: calc(100svh - var(--bottombar-height) - var(--toolbar-height));
}

@media screen and (max-width: 768px) {
    .wrapper {
        height: calc(100svh - var(--bottombar-height) - var(--toolbar-height) - 70px);
    }
}

.pageNavContainer {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    vertical-align: bottom;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-bottom: 2px;
    padding: 12px 0 4px 0;
    border-radius: 5px;
    box-shadow: 0px 4px 6px 0px rgba(140, 153, 198, 0.25);
    z-index: 499;
    position: absolute;
    bottom: 90px;
}

.pageViewContainer {
    display: grid;
    place-items: center;
    overflow: auto;
    width: 100%;
}

.viewer {
    height: calc(100svh - var(--bottombar-height) - var(--toolbar-height));
    width: 100%;
}

.canvasStyle {
    /*width: 100%;*/
    border-bottom: 1px solid #ccc;
}

.form {
    height: 24px;
    width: 24px;
    background-color: #f5f8fe;
    border: none;
    border-radius: 5px;
    color: #718096;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.pageNumber {
    margin: 8px;
    height: 24px;
    width: 24px;
    color: #718096;
    border-radius: 5px;
    border-bottom: 2px solid #f8f9fa;
    text-align: center;
    line-height: 24px;
}

.fullscreen {
    height: 24px;
    width: 24px;
    margin: 8px;
    padding: 0;
    background-color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.zoomIn {
    height: 24px;
    width: 24px;
    margin: 8px;
    padding: 0;
    background-color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.zoomOut {
    height: 24px;
    width: 24px;
    margin: 8px;
    padding: 0;
    background-color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.group2 {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.exitbutton {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
    width: 60px;
    height: 40px;
    /* Space between the input and button */
    margin: 10px 16px 10px 10px;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    background-color: #256eff;
    border: none;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px; /* Gives the button slightly rounded corners */
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0; /* Prevents the button from shrinking */
    justify-content: space-around;
}

.exitbutton:hover {
    background: linear-gradient(180deg, #256eff 0%, #7b5aff 100%);
}
