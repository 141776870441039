input.downloadLink {
    line-height: 0.2em;
    width: 80%;
    height: 30px;
    margin-right: 10px;
    margin-left: 30px;
    margin-bottom: 20px;
    background-color: #f4f7fe;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 260% */
}

.buttonBar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.infomessage {
    margin-top: 8px;
    font-size: 12px;
}

.copyIcon {
    margin-top: -15px;
}

.downloadLinkContainer {
    display: flex;
    align-items: center;
    width: 100%;
}
