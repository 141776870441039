.star {
    margin: 2px;
}

.star_selected {
    fill: var(--color-btn-primary);
}

.star:hover {
    fill: var(--color-btn-primary-hover);
}

.star_hovered {
    fill: var(--color-btn-primary-hover);
}

.ratingAndSubmit {
    width: calc(100% - 20px);
    display: flex;
    vertical-align: center;
    align-items: center;
    justify-content: space-between;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
}

.window {
    width: 50%;
    height: 60%;
    background: #ffffff;
    border-radius: 14px;
    box-shadow: 14px 27px 45px 4px #7090b033;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.toolbar {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.feedbackcontainer {
    width: 100%;
    height: calc(100% - 140px - 5px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: hidden;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
}

.feedbackcontainer::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.feedback {
    border-radius: 5px;
    display: flex;
    width: calc(100% - 20px);
    padding: 10px;
    height: calc(100% - 5px);
    outline: none;
    border: none;
    font-family: "Inter", sans-serif;
    line-height: 20px;
    font-size: 14px;
    color: #494949;
    font-weight: 500;
    resize: none;
    background-color: #f4f7fe;
}

.ratingcontainer {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.title {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #1b2559;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    justify-content: center;
    text-align: left;
}

.controlbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 8px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 30px;
    cursor: pointer;
}

.rating {
    display: flex;
    direction: ltr;
}

.star {
    cursor: pointer;
    color: grey;
    font-size: 50px;
}

.star:hover,
.hovered:not(.selected) {
    color: darkorange;
}

.selected {
    color: orange;
}

.submitbutton {
    width: 150px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px; /* Space between the input and button */
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    background-color: var(--color-btn-primary);
    border: none;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px; /* Gives the button slightly rounded corners */
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0; /* Prevents the button from shrinking */
    justify-content: space-around;
}

.submitbutton:hover {
    background-color: var(--color-btn-primary-hover);
}

.submitbutton.deactivated {
    background-color: #cccccc;
    cursor: not-allowed;
}

.submitbutton.deactivated:hover {
    background: #cccccc;
}

.submitbutton.submitting {
    background-color: #cccccc;
    color: white;
    cursor: progress;
}

.submitbutton.success {
    background-color: #4caf50;
    color: white;
    cursor: default;
}

.animationcontainer {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.likeanimation {
    margin-top: -170px;
    height: 500px;
    width: 500px;
    z-index: 10; /* Ensure it's above other content */
    overflow: visible; /* Ensure the animation is visible outside the bounds */
}

.liketext {
    position: absolute;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #256eff;

    display: flex;
    align-items: center;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, -50%);
}
