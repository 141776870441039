html,
body {
    height: auto;
    overflow: hidden; /* This will disable scrolling */
    /*min-width: 1400px;*/
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100svh; /* 100% of the viewport height */
}

.content-container {
    flex: 1; /* Takes the remaining space */
    display: flex;
    flex-direction: row;
}

.pdf-container {
    flex: 1; /* Takes up 50% of the space */
    overflow-x: auto;
}

.uploadContainer {
    flex: 0.75; /* Takes up 50% of the space */
    overflow-x: auto;
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
}

.chat-container {
    flex: 1; /* Takes up 50% of the space */
    border-left: 1px solid #ccc; /* Optional divider */
}

.summarize-button-container {
    display: flex;
    margin-top: -50px;
    flex: 1; /* Takes up 50% of the space */
    border-left: 1px solid #ccc; /* Optional divider */
    justify-content: center;
    align-items: center;
}

.flashcard-button-container {
    display: flex;
    margin-top: -50px;
    flex: 1; /* Takes up 50% of the space */
    border-left: 1px solid #ccc; /* Optional divider */
    justify-content: center;
    align-items: center;
}

.simple-text-editor-container {
    flex: 1; /* Takes up 50% of the space */
    border-left: 1px solid #ccc; /* Optional divider */
}

.flashcard-container {
    flex: 1; /* Takes up 50% of the space */
    border-left: 1px solid #ccc; /* Optional divider */
}

.conditionalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.centerContent {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.bottom-text {
    height: var(--bottombar-height); /* Fixed height for the bottom div */
    font-family: "Inter", sans-serif;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    text-align: center; /* Centers text horizontally */
    background-color: #ffffff; /* Example background color */
    position: fixed; /* Fixed position */
    bottom: 0; /* Stick to the bottom */
    width: 100%; /* Take full width */
    color: #718096;
    display: flex;
    flex-direction: row;
}

.regenerateFlashcardsText {
    text-align: center;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;

    letter-spacing: 0;
    max-width: 90%;
    color: #000;
    margin-bottom: 15px;
    margin-top: 0;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .bottom-text {
        display: none;
    }

    .regenerateFlashcardsText {
        font-size: 16px;
        line-height: 28px;
    }
}

.left-half,
.right-half {
    width: 50%; /* Each child takes up half the width */
    display: flex; /* Also makes them flex containers */
    align-items: center; /* Vertically center the content */
    justify-content: space-around;
}

.loadingAnimationUpload {
    padding-top: 40px;
    flex: 1; /* Takes up 50% of the space */
    overflow-x: auto;
    display: flex; /* Enables flexbox */
    flex-direction: column;
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: #718096;
}

.paragraphUpload {
    margin-block-start: 0;
    margin-block-end: 0;
}

.loadingAnimationUploadParagraphContainer {
    margin-top: 16px;
}

.progressbar {
    margin-top: 0;
}

.loadingAnimationSummary {
    padding-top: 40px;
    flex: 1; /* Takes up 50% of the space */
    overflow-x: auto;
    display: flex; /* Enables flexbox */
    flex-direction: column;
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
    font-family: "Inter", sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #718096;
}

.text {
    gap: 0;
}

.joke {
    font-size: 12px;
    line-height: 6px;
    margin-bottom: -40px;
}
