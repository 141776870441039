.tinyEditor {
    padding: 10px 10px 10px 15px;
    width: 90%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: calc(100% - 20px);
    scrollbar-width: none;
}

.tinyEditor .mce-content-body {
    flex: 1;
    border: 1px dotted green;
}