.btn {
    width: 192px;
    height: 46px;
    border: none;
    border-radius: 5px;
    color: white;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn svg {
    width: 28px;
    height: 28px;
    margin-right: 5px;
    margin-left: 5px;
}

.btn:active {
    border: none;
}

@media screen and (max-width: 768px) {
    .btn {
        width: 40%;
    }
}
.uncover,
.restart,
.next {
    background: linear-gradient(135deg, #e7ecf6 -20.3%, #256eff 91.78%);
}
.uncover:hover,
.restart:hover,
.next:hover {
    background: linear-gradient(170deg, #e7ecf6 -20.3%, #256eff 91.78%);
}

.correct,
.next-correct {
    background: linear-gradient(135deg, #dafaee -20.3%, #31c17c 91.78%);
}

.correct:hover,
.next-correct:hover {
    background: linear-gradient(170deg, #dafaee -20.3%, #31c17c 91.78%);
}

.incorrect,
.next-incorrect {
    background: linear-gradient(135deg, #feb580 -20.3%, #a7195d 91.78%);
}

.incorrect:hover,
.next-incorrect:hover {
    background: linear-gradient(170deg, #feb580 -20.3%, #a7195d 91.78%);
}
