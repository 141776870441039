.hide_mobile {
    display: initial;
}

.show_mobile {
    display: none;
}

.Toolbar {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;

    width: 100%;
    height: var(--toolbar-height);

    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px 0 rgba(140, 153, 198, 0.25);
    backdrop-filter: blur(5px);

    z-index: 500;
}

.Toolbar_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Toolbar_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Toolbar > div {
    flex: 1;
}

.Toolbar_bottom {
    width: 100%;
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.48) 22.33%,
        rgba(128, 169, 249, 0.51) 32.22%,
        rgba(2, 84, 242, 0.6) 49.87%,
        rgba(128, 169, 249, 0.54) 67.25%,
        rgba(255, 255, 255, 0.48) 75.81%
    );
}

.logo {
    height: 26px;
    padding: 0 0 0 20px;
}

.btn_filemanager {
    border: none;
    cursor: pointer;
    margin: 0 16px 0 20px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: transparent;
}

.btn_filemanager svg {
    position: relative;
    left: -3px;
    top: 1px;
}

.btn_filemanager:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_save_summary {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 8px;
    padding: 3px;
    width: 30px;
    height: 30px;
}

.btn_save_summary:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_save_summary .spinner {
    width: 24px;
    height: 24px;
}

.btn_download_summary {
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin: 0 8px;
    text-align: center;
}

.btn_download_summary:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_download_summary .spinner {
    width: 24px;
    height: 24px;
}

.btn_download_cards {
    background: none;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin: 0 8px 0 8px;
    padding: 3px;
    text-align: center;
}

.btn_download_cards:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_download_cards .spinner {
    width: 24px;
    height: 24px;
}

.btn_clear_chat {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 8px;
    padding: 3px;
    width: 30px;
    height: 30px;
}

.btn_clear_chat:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_profile {
    border: none;
    cursor: pointer;
    margin: 0 20px 0 20px;
    padding: 3px;
    height: 30px;
    width: 30px;
    border: 1px;
    border-radius: 5px;
    background-color: #fff;
}

.btn_burgernNav {
    display: none;
    border: none;
    cursor: pointer;
    margin: 0 20px 0 20px;
    padding: 3px;
    height: 30px;
    width: 30px;
    border: 1px;
    border-radius: 5px;
    background-color: #fff;
}

.burgerMenu {
    display: block;
    position: absolute;
    z-index: 999;
    top: 46px;
    right: 0;
    width: 50%;
    background: #fff;
    box-shadow: 0 4px 6px 0 rgba(140, 153, 198, 0.25);
    backdrop-filter: blur(5px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.burgerMenu li {
    text-align: right;
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid var(--color-btn-primary);
    padding: 10px;
    gap: 10px;
}

.btn_profile svg {
    height: 24px;
    width: 24px;
}

.btn_profile:hover {
    border-radius: 5px;
    background: #f5f8fe;
    flex-shrink: 0;
}

.btn_feedback {
    padding: 4px 10px 3px 10px;
    border: none;
    background: none;
    color: #000;
    border-radius: 5px;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
    cursor: pointer;
    height: 30px;
}

.btn_feedback:hover {
    border-radius: 5px;
    border: 1px var(--color-btn-secondary-hover);
    background: var(--color-btn-secondary-hover);
}

.modeSelector {
    padding: 0;
    align-self: center;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    background-color: #f5f8fe;
    border-radius: 5px;
}

.modeSelector li {
    display: flex;
    width: 110px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.84px;
    cursor: pointer;
}

.modeSelector > li.active {
    background-color: #ffffff;
    color: #256eff;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0 0 6px 0 rgba(151, 157, 184, 0.4);
    outline: 2px solid #fff;
    z-index: 2;
}

.modeSelector > li.active:hover {
    color: #256eff;
    outline: 2px solid #fff;
    z-index: 2;
    box-shadow: 0 0 6px 0 rgba(151, 157, 184, 0.4);
}

.modeSelector > li:hover {
    background-color: #ffffff;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0 0 6px 0 rgba(140, 153, 198, 0.25);
}

.btn_studymode {
    background: none;
    border: none;
    cursor: pointer;
    padding: 3px;
    margin: 0 8px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px #fff;
}
.btn_repeat {
    background: none;
    border: none;
    cursor: pointer;
    padding: 3px;
    margin: 0 8px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px #fff;
}

.btn_repeat:hover {
    border-radius: 5px;
    border: 1px #f5f8fe;
    background: #f5f8fe;
}

.btn_studymode:hover {
    border-radius: 5px;
    border: 1px #f5f8fe;
    background: #f5f8fe;
}

.btn_studymode.active {
    color: #fff;
    border-radius: 5px;
    border: 1px #256eff;
    background-color: var(--color-btn-primary);
}

.btn_studymode.active svg path {
    color: #fff;
    stroke: #fff;
}

.btn_reset {
    background: none;
    border: none;
    cursor: pointer;
    padding: 3px;
    margin: 0 8px 0 8px;
    height: 30px;
}

.btn_reset:hover {
    border-radius: 5px;
    border: 1px #f5f8fe;
    background: #f5f8fe;
}

@media screen and (max-width: 900px) {
    .btn_feedback {
        display: none;
    }

    .btn_filemanager {
        display: none;
    }
}

/* landscape-phones & portrait-Tablets */
@media screen and (max-width: 768px) {
    .btn_profile {
        display: none;
    }

    .btn_feedback {
        display: none;
    }

    .btn_burgernNav {
        display: initial;
        color: #000;
    }

    .modeSelector {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .hide_mobile {
        display: none;
    }

    .show_mobile {
        display: initial;
    }
}
