.studyCardOnlyTitle {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 372px;
    width: 800px;
    box-shadow: 1px 2px 45px 4px #7090b033;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 10px rgba(140, 153, 198, 0.25));
}

@media screen and (max-width: 768px) {
    .container {
        margin-left: 10px;
        width: 90%;
        filter: none;
    }
}

.content {
    display: flex;
    min-height: 372px;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.cardColoring {
    width: 100%;
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.48) 10%,
        rgba(128, 169, 249, 0.51) 20%,
        rgba(2, 84, 242, 0.6) 50%,
        rgba(128, 169, 249, 0.54) 80%,
        rgba(255, 255, 255, 0.48) 90%
    );
}
.cardHeadline {
    box-sizing: border-box;
    width: 100%;
    /*height: 62px;*/
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 10px; /* Consolidate padding here */
    display: flex; /* Flexbox to align the text */
    align-items: center; /* Vertically center the text */
    overflow-x: auto; /* Prevents content overflow */
}

.headline {
    /*height: 100%;*/
    border: none;
    padding: 20px 16px 10px 16px;
    outline: none;
    text-align: left;
    color: #494949;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    resize: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.headlineLarge {
    display: flex;
    flex: 1;
    padding: 0 20px;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
    text-align: center;
    color: #494949;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 500;
    width: calc(100% - 40px);
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.cardContent {
    margin-top: 5px;
    box-sizing: border-box;
    width: 100%;
    height: initial;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}

.tinyEditor {
    box-sizing: border-box;
    margin-top: -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: inherit;
}

.number {
    justify-content: flex-end;
    font-family: "Inter", sans-serif;
    color: #494949;
    font-size: 14px;
    text-align: right;
    margin: 15px;
}

.controlIcons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 74px;
    height: 100%;
}

.icon {
    margin-left: 25px;
    width: 24px;
    max-height: 24px;
    opacity: 0.7;
    cursor: pointer;
}

.cliploader {
    max-width: 20px;
    max-height: 20px;
    opacity: 0.7;
}

.separator {
    width: 100%;
    height: 8px;
}

.svg-icon {
    width: 24px; /* Equivalent to w-6 */
    height: 24px; /* Equivalent to h-6 */
    object-fit: contain;
}

.scrollable {
    overflow-y: auto;
    outline: none;
}

.answer {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
}

.answerInput {
    padding-top: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 14px;
    cursor: pointer;
}

.answerIsCorrect {
    cursor: pointer;
}

.correctAnswer {
    background: linear-gradient(200deg, #dafaee -20.3%, #31c17c 91.78%);
    border-radius: 5px;
}

.wrongAnswer {
    background: linear-gradient(200deg, #feb580 -20.3%, #a7195d 91.78%);
    border-radius: 5px;
}

.menuContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

input[type="checkbox"].answerCheckbox {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 17px 0 0 10px;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 1.5px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
}

input[type="checkbox"].answerCheckbox::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em currentColor;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"].answerCheckbox:checked::before {
    transform: scale(1);
}
