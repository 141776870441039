.button {
    color: #000;
    align-items: center;
    display: flex;
    padding: 3px 1px 3px 6px;
    margin: 0 8px;
    letter-spacing: 0;
    background: #fff;
    border: none;
    border-radius: 4px;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
    z-index: 1001;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
}

.button svg {
    width: 24px;
    height: 24px;
}

.button:hover {
    border-radius: 5px;
    background: #F5F8FE;
    flex-shrink: 0;
}

.title {
    z-index: 3;
    position: absolute;
    font-family: Inter, sans-serif;
    font-size: 11px;
    left: 15px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    vertical-align: middle;
}

.icon {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-top: 2px;
    margin-left: 0;
}

/*.button:has(.title) .icon {*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    vertical-align: middle;*/
/*    margin-left: 8px;*/
/*}*/

.dropdown {
    position: relative;
}

.dropdownContent {
    color: #000;
    display: block;
    position: absolute;
    top: 100%; /* Align it right below the button */
    left: 0.5em;
    width: 130px;
    margin-top: -3px;
    padding-top: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000; /* High value to ensure it's on top */
}

.dropdownContent button {
    color: #000;
    padding: 5px 8px;
    background: none;
    border: none;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
}

.dropdownContent .selected {
    font-weight: bold;
}

.dropdownContent button:hover {
    background: #F5F8FE;
    border-radius: 5px;
}