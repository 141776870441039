.button {
    align-items: center;
    display: flex;
    padding: 0 1px 0 6px;
    letter-spacing: 0;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    justify-content: space-around;
    cursor: pointer;
    height: 30px;
    z-index: 10001;
    position: relative;
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
}

.button:hover {
    border-radius: 5px;
    background: #F5F8FE;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    vertical-align: middle;
    text-align: center;
}

.button:has(.title) .icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-left: 1px;
}

.dropdown {
    position: relative;
}

.dropdownContent {
    color: #000;
    display: block;
    position: absolute;
    top: 100%; /* Align it right below the button */
    left: 0;
    width: 50px;
    margin-top: -3px;
    padding-top: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000; /* High value to ensure it's on top */
}

.dropdownContent button {
    color: #000;
    padding: 4px 8px;
    background: none;
    border: none;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
}


.dropdownContent .selected {
    font-weight: bold;
}

.dropdownContent button:hover {
    background: #F5F8FE;
    border-radius: 5px;
}