.main {
    padding-top: 10px;
    height: calc(100svh - 25px - 13px);
    min-height: calc(100svh - 35px - 100px - 35px);
    max-height: calc(100svh - 35px - 100px - 35px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}

.helperBubbles {
    display: flex;
    gap: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 32px;
}
.helperBubble {
    /*animation: fadeIn 1s;*/
    background-color: #ffffff;
    color: #256eff;
    display: flex;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.84px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 6px 0 rgba(151, 157, 184, 0.4);
}

.helperBubble:hover {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(151, 157, 184, 0.4);
    border: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.helperBubble:hover {
    background-color: var(--color-btn-secondary);
    border: none;
    color: var(--color-btn-primary);
    outline: 2px solid var(--color-btn-secondary);
}

@media screen and (max-width: 768px) {
    .main {
        height: calc(100svh - 25px - 13px);
        min-height: calc(100svh - 35px - 100px - 70px - 35px);
        max-height: calc(100svh - 35px - 100px - 70px - 35px);
    }
}

.clipBoardIcon {
    cursor: pointer;
    margin-right: 5px;
}

.main::-webkit-scrollbar {
    width: 0.25rem;
}

.main::-webkit-scrollbar-track {
    background: #ffffff;
}

.main::-webkit-scrollbar-thumb {
    background: #f4f3f8;
}

.form {
    height: auto; /* Adjusts form height based on content */
    background: #f9fafc;
    box-shadow: 0 4px 6px 0 rgba(140, 153, 198, 0.25);
    border-radius: 5px;
    padding: 6px 0;
    display: flex;
    align-items: center; /* Vertically centers the input and button */
    margin: 1px 25px 0 24px;
}

.input {
    font-family: "Inter", sans-serif;
    width: 75%;
    background: transparent;
    outline: none;
    border: none;
    padding: 2px 0 2px 32px;
    border-radius: 7px;
    flex-grow: 1;

    white-space: pre-wrap; /* Allows text to wrap */
    overflow-wrap: break-word; /* Ensures words break to prevent overflow */
    resize: none; /* Prevent resizing */
    overflow-y: hidden; /* Hide the overflow initially */
    height: auto; /* Set the height to auto to allow for expansion */
    max-height: 100px; /* Set a max-height for scrolling */

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button {
    background: transparent;
    width: 35px;
    height: 20px;
    margin: 10px 5px 10px 5px;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    border: none;
    color: #000;
    text-align: center;
    text-decoration: none;
    border-radius: 5px; /* Gives the button slightly rounded corners */
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0; /* Prevents the button from shrinking */
    justify-content: space-around;
}

.button:hover {
    background: var(--color-btn-secondary-hover);
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.message {
    display: flex;
    align-items: center;
    margin-bottom: 0; /* Gap between messages */
    margin-right: 4%;
}

.sentImg {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the avatar circular */
    margin-left: 24px;
    margin-right: 24px; /* Gap between avatar and message bubble */
    box-shadow: 0 0 0 1px #c3cad3;
    margin-bottom: 16px;
}

.defaultImg {
    padding: 6px;
    width: 28px;
    height: 28px;
    border-radius: 50%; /* Makes the avatar circular */
    margin-left: 24px;
    margin-right: 24px; /* Gap between avatar and message bubble */
    box-shadow: 0 0 0 1px #c3cad3;
    margin-bottom: 16px;
}

.sentMessage {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 0 20px;
    margin: 10px 0;
}
.sendIcon {
    width: 25px;
    height: 25px;
}

.sentText {
    text-align: right;
    border-radius: 5px;
    background: #f5f8fe;
    max-width: 60%;
    font-family: "Inter", sans-serif;
    color: rgba(13, 13, 13);
    font-style: normal;
    font-weight: 400;
    position: relative;
    line-height: 24px;
    font-size: 16px;
    padding: 4px 20px 6px 20px; /* Adjusted right padding for symmetry */
}

.tutorMessage {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 10px 0;
}

.tutorImg {
    padding: 6px;
    width: 28px;
    height: 100%;
    margin-left: 24px;
    margin-right: 0; /* Gap between avatar and message bubble */
    margin-bottom: 28px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.tutorLogoDivider {
    width: 1px;
    margin-left: 10px;
    height: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 0.48) 10%,
        rgba(128, 169, 249, 0.51) 30%,
        rgba(2, 84, 242, 0.6) 50%,
        rgba(128, 169, 249, 0.54) 70%,
        rgba(255, 255, 255, 0.48) 90%
    );
}

.tutorText {
    padding: 4px 20px 6px 0; /* Adjusted right padding for symmetry */
    max-width: 80%; /* To ensure the bubble doesn't take the entire width */
    min-width: 80%;
    height: auto;
    font-family: "Inter", sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: left;
    margin-top: 0;
    color: rgba(13, 13, 13);
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    line-height: 24px;
}

.loaderBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: white; /* Adjust to match your chat bubble color */
    box-shadow: 1px 2px 45px 4px #7090b033;
    border-radius: 16px;
    max-width: 250px; /* Adjust as needed */
    margin-bottom: 14px;
}

.sentText::first-line,
.receivedText::first-line {
    text-indent: 0; /* Ensures no indent on the first line */
    padding-left: 0; /* Ensures no padding on the left side of the first line */
}

.h1LineHeight {
    line-height: 1.2;
}

.reference {
    background: red;
}

.reference {
    display: inline-block;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3498db;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 12px; /* Adjusted font size to fit the smaller circle */
    font-weight: bold;
    position: relative;
    top: -2px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}

/* Hover effect */
.reference:hover {
    background-color: #2980b9;
    transform: scale(1.1);
}

/* Optional: add a shadow effect */
.reference {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Optional: focus effect for accessibility */
.reference:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5);
}

sup {
    float: right;
    font-size: initial;
    vertical-align: middle;
}
