.uploadContainer {
    position: relative; /* Added this */
    max-width: 85%;
    border-radius: 5px;
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px 0 rgba(140, 153, 198, 0.25);
    background-image: url("./background-line-bottom.svg"), url("./background-line-top.svg");
    background-repeat: no-repeat;
    background-position: bottom right, top left;
}

.uploadIcon svg {
    width: 65px;
    height: 65px;
    margin-top: 20px;
}

.generateBtn {
    border-radius: 5px;
    background: var(--color-btn-primary);
    padding: 10px 30px;
    border: none;
    margin-bottom: 30px;
    height: 45px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.generateBtn:hover {
    background: var(--color-btn-primary-hover);
}

.uploadTextTitle,
.uploadTextSubtitle {
    text-align: center;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 188.889% */

    letter-spacing: 0;
    max-width: 90%;
    color: #000;
    margin-top: 17px;
}

.uploadArea {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #000;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadBtn {
    display: flex;
    margin: 10px 5px;
    height: 30px;
    padding: 5px 3px 5px 10px;
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 4px 0px rgba(140, 153, 198, 0.25);

    vertical-align: center;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.uploadBtn svg {
    width: 20px;
    height: 20px;
    margin-left: 3px;
}

.uploadBtn:hover {
    background-color: #f4f7fe;
}

.generateButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.dropdown {
    position: relative;
}

.dropdownContent {
    margin: -5px auto 0 5px;
    left: 0px;

    display: block;
    position: absolute;
    background-color: #f4f7fe; /* Semi-transparent like buttons */
    width: calc(100%-30px);
    min-width: calc(100% - 10px);
    font-size: 12px;

    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px; /* Rounded corners like buttons */
    overflow: hidden; /* Ensures the child buttons don't break style */
    z-index: 1;
}

.dropdownContent span {
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: left;
    font-weight: normal;
}

.dropdownContent span.active {
    font-weight: bold;
}

.dropdownContent span:hover {
    background-color: #fff;
    border-radius: 5px;
}

.dropdownContent input {
    margin: 0 5px;
    gap: 5px;
    width: 25px;
    border: none;
    border-radius: 5px;
}
