@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
    --toolbar-height: 45px;
    --bottombar-height: 12px;
    --color-btn-primary: #256eff;
    --color-btn-primary-hover: #224db2;
    --color-btn-secondary: #f5f8fe;
    --color-btn-secondary-hover: #f5f8fe;
    --color-btn-danger: rgba(202, 48, 77, 0.95);
    --color-btn-danger-hover: #a71933f2;
    --primary-font: "Inter";
}

.mce-content-body {
    flex: 1;
    height: 100%;
    outline: none;
}
