.btn {
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    padding: 0 20px;
    text-align: center;
    vertical-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.06em;
    cursor: pointer;
    background: #f5f8fe;
    margin: 0 10px;
}

.btn_primary {
    background: var(--color-btn-primary);
    color: #fff;
}

.btn_primary:hover {
    color: #fff;
    background: var(--color-btn-primary-hover);
}

.btn_danger {
    color: #fff;
    background: var(--color-btn-danger);
}

.btn_danger:hover {
    color: #fff;
    background: var(--color-btn-danger-hover);
}
