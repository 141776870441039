.container { 
    box-sizing: border-box;
    max-width: 100%;
    height: 372px;
    width: 800px;
    box-shadow: 1px 2px 45px 4px #7090B033;
    border-radius: 5px;
    position: relative;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px 25px;
  }
  
  .card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .placeholder {
    width: 100%;
    height: 100%;
  }
  
  .headlineLarge {
    display: flex;
    align-items: center;  /* Vertical centering */
    justify-content: center;  /* Horizontal centering */
    text-align: center;
    color: #494949;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow-x: auto;
    overflow-y: auto;
  }