.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(9px);
    z-index: 1000; /* High z-index to ensure it's above other content */
}

.modal {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px; /* Optional: rounded corners */
    width: 80%; /* or fixed width, depending on your preference */
    margin: auto;
    overflow: hidden;
}

.header {
    margin: 10px 10px 0 20px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-family: "Inter", "sans-serif";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.96px;
}

.no_header {
    padding: 10px 20px 0 20px;
    margin-bottom: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-family: Inter, "sans-serif";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.header span {
    font-weight: bold;
}

.header button,
.no_header button {
    border: none;
    background: none;
    font-size: 1.5em;
    cursor: pointer;
}

.content {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    max-height: 80vh; /* Maximum height to 80% of the viewport height */
    overflow-y: auto; /* Enables vertical scrolling */

    color: #000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
