.infoBanner {
    position: fixed;
    top: 60px; /* Margin from the top */
    left: 50%; /* Center the banner horizontally */
    transform: translateX(-50%); /* Shift it back by half of its width to truly center it */
    width: 90%; /* Making the banner shorter in width */
    max-width: 600px; /* Optional: further control over the max width */
    background-color: rgba(
        211,
        47,
        47,
        0.9
    ); /* Slightly darker background for better readability */
    font-family: "Inter", sans-serif;
    font-weight: 800;
    color: white;
    text-align: center;
    padding: 10px 0;
    z-index: 1000; /* High z-index to overlay on top of other components */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none; /* Allows clicks to pass through when not visible */
    border-radius: 5px; /* Optional: adds rounded corners for better aesthetics */
}

.show {
    opacity: 1;
}

.blue {
    background-color: var(--color-btn-primary);
}
